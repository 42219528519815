#drawer-container {
  background-image: url(https://in.sugarcosmetics.com/Login_bg.svg);
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
#numberBox {
  border: 1px solid black;
  font-size: 18px;
  font-weight: 400;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
}
#requestButton {
  font-weight: 400;
    font-size: 14px;
  background-color: #adadad;
  color: white;
  border: none;
}
#alertOtpbox{
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
}
#otpTextbox1{
    font-size: 14px;
    font-weight: 400;
}
#otpTextbox2{
    font-size: 14px;
    font-weight: 400;
    border: 1px solid black;
    outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
}
#verifyOtpBox{
    font-size: 14px;
    color: white;
}