body {
    background-color: #f5f5f5;
}

.crousel1 {
    margin-top: 40px;
}

h1.ryf {
    margin-left: 674px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 100;
    font-size: x-large;
}
h1.quick_tips{
    margin-left: 590px;
    font-size: x-large;
    font-weight: 100;
    padding-top: 10px;
    padding-bottom: 25px;
}

h1.bs {
    font-weight: 100;
    font-size: x-large;
}

h1.latte {
    font-weight: bolder;
    font-size: small;
    padding-bottom: 17px;
    padding-top: 0px;
}


.img1 {
    width: 950px;
    max-height: 5%;
    padding: 0px 45px 10px;
}


.ss {
    background-color: black;
    color: white;
    width: 70%;
    height: 40px;
    font-size: 15px;
}
.rs{
    font-size: medium;
    font-weight: bolder;
}

.bs-container {
    width: 93%;
    text-align: center;
    margin-left: 3%;
}

.content-div{
    background-color: white;
    /* gap: 2%; */
    width: 80%;
    /* height: 50px; */
}

.crousel2{
    /* width: 95%; */
    height: 400px;
    margin-left: 5%;
}
.hot_deals{
    margin-left: 722px;
    font-size: x-large;
    font-weight: 200;
    padding-top: 25px;
    padding-bottom: 15px;
}
.Img{
    padding: 10px 20px 10px;
}
.newly_launched{
    margin-left: 647px;
    margin-top: 25PX;
    margin-bottom: 15PX;
    font-size: x-large;
    font-weight: 400;
}
.container{
    width: 93%;
    text-align: center;
    margin-left: 3%;
    font-size: x-large;
    padding-top: 25px;
    padding-bottom: 16px;
    padding-left: 25px;
}

.Ruppes{
    font-size: medium;
    font-weight: bold;
}
.top_picks_of_the_week{
    margin-left: 647px;
    padding-top: 25px;
    padding-bottom: 15px;
    font-size: x-large;
}
.super_savers{
    margin-left: 47px;
    padding-top: 25px;
    padding-bottom: 15px;
}
.skincare_basics{
    padding-bottom: 15px;
}