html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0%;
    font-family: 'Poppins', sans-serif;
}

#headerForLargeScreen {
    display: block;
    /* -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black; */
    
}



.img {
    width: 100%;
    height: 100%;
}

.Header {
    display: flex;
    width: 100%;
    height: 85px;
    border: 1px solid #DCDCDC;
    border-right: none;
    border-left: none;
    background-color: rgb(248, 249, 250);
    align-items: center;
    justify-content: space-between;
}

.Header__Logo {
    width: 15%;
    height: 70%;
    /* border: 1px solid black; */
    margin-top: 0.9%;
    margin-left: 2%;
    cursor: pointer;
}

.Header__Search {
    
    width: 40%;
    height: 52%;
    display: flex;
    margin-top: 1.5%;
    margin-left: 2%;
}

.Header__Search__inputBox {
    width: 100%;
    height: 95%;
    padding: 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    border-style: none;
    font-size: 15px;
    outline: none;
}

.Header__Search>p{
    color: white;
    font-size: 17px;
    text-align: center;
    cursor: pointer;
    padding: 8px 27px;
    background-color: black;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.Header__Login {
    width: auto;
    height: 50%;
    /* border: 1px solid blue; */
    font-size: 14px;
    font-style: italic;
    margin-top: 2.3%;
    margin-left: 10%;
    cursor: pointer;
    color: #757575;
    display: flex;
    align-items: center;
    gap:10px;
}

.Header__LikeCart {
    display: flex;
    align-items: center;
    width: 10%;
    height: 50%;
    margin-top: 1.8%;
    margin-left: 1%;
    /* border: 1px solid violet; */
    cursor: pointer;
    gap:10px;
}

.Header__LikeCart>div {
    /* width: 40%; */
    /* border: 1px solid blue; */
    text-align: center;
    font-size: 140%;
}

.Header__LikeCart>div>a>i {
    margin-top: 13%;
}

.Header__LikeCart>div>a {
    color: black;
    text-decoration: none;
}

.Header__Login__P {
    text-decoration: none;
    color: black;
}

.Header__Login__P:hover {
    text-decoration: underline;
}

.navBar {
    width: 100%;
    height: 5px;
    /* border: 1px solid black; */
    display: flex;
}

.navBar>div {
    width: auto;
    height: 70%;
    margin-left: 1.5%;
    /* border: 1px solid black; */
    text-align: center;
    margin-top: 0.5%;
    cursor: pointer;
}

.navBar>div>i {
    color: #757575;
}

.dropbtn {
    font-weight: bold;
}

.dropbtn:hover {
    color: #FC2779;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.navBar__content {
    display: none;
    position: absolute;
    width: 700px;
    border: 1px solid #DCDCDC;
    background-color: white;
    z-index: 1;
}

.navBar__content>div>a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.navBar__content>div>a:hover {
    color: #ff619e;
    /* font-size: 18px; */
    transform: translateX(12px);
    transition-duration: 0.3s;
}

.navBar__content>div {
    margin-left: 5%;
}

.navBar__content>div>p {
    font-weight: bold;
    text-align: left;
}

.navBar__content>div>p:hover {
    color: #ff619e;
}

.dropdown:hover .navBar__content {
    display: block;
    display: flex;
}

#navBar__content1 {
    width: 200px;
}

#headerForSmallScreen {
    width: 100vw;
    /* display: flex; */
    display: none;
}