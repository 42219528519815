.Footer {
    width: 100%;
    height: auto;
    background-color: black;
    border: 1px solid black;
    border-left: none;
    border-right: none;
    margin-top: 5%;
}

p:hover{
    cursor: pointer;
    color: white;
    text-decoration: underline;
}

.Footer__Logo {
    width: 10%;
    height: 140px;
    /* border: 1px solid white; */
    margin: auto;
    margin-top: 0.5%;
}

.iconBox {
    width: 10%;
    height: 140px;
    /* border: 1px solid white; */
    margin: auto;
    margin-top: 5%;
}

.iconBox {
    margin-top: 0%;
    height: auto;
    display: flex;
    justify-content: space-around;
}

.icon>i {
    color: whitesmoke;
    cursor: pointer;
}

.Footer__Info {
    color: white;
    /* border: 1px solid white; */
    width: 95%;
    height: auto;
    margin-left: 2%;
    margin-top: 3%;
    display: flex;
}

.Footer__Info>div {
    margin-right: 2%;
    /* border: 1px solid white; */
    width: auto;
}

.Footer__Info>div>strong {
    letter-spacing: 2px;
}

.Footer__Info>div>p {
    color: rgb(124, 124, 124);
    line-height: 18px;
}

.Footer__Info>div>p:hover, .mobileNum>p:hover {
    color: white;
    text-decoration: underline;
    cursor: pointer;
}

#info__1 {
    width: 100%;
    margin-left: 8%;
}

#info__1>strong {
    color: white;
}

.info_links {
    display: flex;
    color: rgb(124, 124, 124);
    gap: 5%;
    margin: 25px 0;
}

.dash {
    width: 15%;
    height: 3px;
    background-color: white;
    margin-bottom: 35px;
}

.mobileNum {
    display: flex;
    width: 100%;
    margin-top: 25px;
}

.mobileNum>i {
    color: white;
}

.mobileNum>i {
    height: 25px;
    width: 10%;
    font-size: 22px;
}

.mobileNum>p {
    width: 100%;
    margin-top: 1px;
    font-size: 15px;
    color: rgb(124, 124, 124);
}

#dateTime {
    margin-top: 0%;
    color: rgb(124, 124, 124);
    text-decoration: none;
    cursor: text;
    font-size: 12px;
    width: 120%;
}

/* #spcaeTopBottom {
    margin-top: 10%;
    margin-bottom: 10%;
} */

.glogoBanner {
    display: flex;
    width: 85%;
    height: 30px;
    gap: 4%;
    /* border: 1px solid white; */
}

.glogoBanner>div {
    width: 42%;
    height: 100%;
    margin-left: 2%;
    cursor: pointer;
}

.form {
    display: flex;
    margin-top: 40px;
}

#bannerGetApp {
    width: 30%;
    margin-left: 3%;
}

#bannerGetApp>strong, #newsLetter>strong {
    color: white;
    letter-spacing: 1px;
}

#newsLetter {
    /* border: 1px solid white; */
    width: 40%;
    height: 200px;
    margin-left: 8%;
}

#newsTextBox {
    display: flex;
    width: 100%;
    height: 18%;
    margin-top: 30px;
    border-top: none;
    border-right: none;
    border-left: none;
}

.newsText {
    width: 50%;
    padding: 25px 0;
    background-color: inherit;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    color: rgb(124, 124, 124);
}

.signUpButton {
    padding: 5px;
    margin-top: 10px;
    width: 14%;
    height: 40px;
    border: none;
    outline: none;
    background-color: #FC2779;
    cursor: pointer;
    color: white;
    border-radius: 5px;
}

.signUpButton:hover {
    background-color: #ff0062;
}

.dividePage {
    width: 92%;
    height: 1px;
    background-color: grey;
    margin: auto;
    margin-top: 3%;
}

.copyRight {
    width: 50%;
    height: 40px;
    /* border: 1px solid white; */
    margin: auto;
    margin-top: 3%;
    color: white;
    text-align: center;
    margin-bottom: 1%;
}

.contact {
    display: flex;
    gap: 5%;
    margin-left: 8%;
    margin-bottom: 30px;
}

.contact>div>strong {
    color: white;
}

.contact-info>strong>i {
    margin-right: 5px !important;
}


.contact>div>p {
    color: rgb(124, 124, 124);
}

.links {
    display: flex;
    gap: 7%;
    width: 65%;
}

.links>div>strong {
    color: white;
}

.links>div>p {
    color: rgb(124, 124, 124);
}