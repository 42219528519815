.productcontainer{
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(4,1fr);
    gap:50px;
    padding:70px
}


.productbox{
    text-align: center;
    display: flex;
    flex-direction:column ;
    justify-content: space-between;
    font-size: 13px;
    
}

.imagebox{
    padding: 15px;
}

.imagebox>h3{
    font-weight: bolder;
    color: #808080;
    
}
.productbox:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.productbox>div{
    justify-content: center;
}

.cartbox{
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    border:2px solid #212529;
    justify-content: space-around;

    visibility: hidden;
}
.cartbox>p{
    background-color: #212529;
    box-sizing: border-box;
    color:white;
    font-size: 13px;
    padding:2% ;
}

.mainproductdiv{
   width: 90%;
   margin:auto;
   margin-top:10px;
}

.selectbox{
    display: flex ;
    justify-content: end;
    gap:10px;
    align-items: center;
}


@media all and (max-width:780px) {
    .productcontainer{
        grid-template-columns: repeat(2,1fr);
    }
}

@media all and (min-width:781px) and (max-width:999px){
    .productcontainer{
        grid-template-columns: repeat(3,1fr);
    }
}